<template>
<div class="attendance-form flex-1">
  <div class="title">
    <b-form-input
      v-if="Boolean(selectedForm.canEditName)"
      :value="selectedForm.label"
      :formatter="formatName"
      @change="e => changeFormName(e)"
      debounce="500"
    />
    <h2 v-else>
      {{ selectedForm.label }}
    </h2>
  </div>
  <p class="message-box">
    <InfoCircle class="icon" />
    <span>
      Para adicionar campos à sua consulta, clique no botão
      <Plus class="icon no-spacing" /> localizado nos campos dentro do
      menu lateral à esquerda. As alterações feitas serão salvas
      automaticamente.
    </span>
  </p>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Plus from '@/assets/icons/plus.svg'
import InfoCircle from '@/assets/icons/info-circle.svg'

export default {
  components: {
    Plus, InfoCircle,
  },
  props: {
    form: Object,
  },
  computed: {
    ...mapState({
      selectedForm: state => state.attendance.form.selected,
    }),
  },
  methods: {
    ...mapActions('attendance/form', ['setAttendanceSelectFormLabel']),
    formatName(e){
      return String(e).substring(0, 20)
    },
    changeFormName(label){
      if(label && label.length)
        this.setAttendanceSelectFormLabel(label)
    }
  }
}
</script>

<style lang="scss" scoped>
.attendance-form {
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 5%;
    input, h2 {
      padding: 10px;
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 134%;
      letter-spacing: -0.01em;
      color: #0c1d59;
    }
  }

  .message-box {
    background-color: #e6f9ff;
    border-radius: 8px;
    padding: 16px 26px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 0 0 56px 0;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 25px;
      stroke: #0088b3;
      &.no-spacing {
        width: 20px;
        height: 20px;
        margin-right: 0;
      }
    }

    &.warning {
      justify-content: start;
      margin: 0 16px;
      background-color: #fff0e6;

      .icon {
        stroke: #b34b00;
      }
    }
  }

  .form-group-title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 18px;
    color: var(--dark-blue);
    margin: 40px 16px 24px 16px;
  }
}
</style>